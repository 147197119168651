<template>
  <div class="cusListAdd-container">
    <el-drawer
      class="cusListAdd-drawer"
      :title="tableRowId ? '店铺详情' : '新增店铺'"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div class="drawer-container">
        <el-form
          :model="ruleFormAdd"
          :rules="rulesAdd"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="店铺logo" prop="oldPassword">
            <div class="upload-icon-container">
              <el-button class="common-screen-btn">选择图片</el-button>
              <input type="file" class="upload-icon-input" />
            </div>
          </el-form-item>
          <el-form-item label="店铺名称" prop="oldPassword">
            <el-input
              class="common-screen-input_330"
              placeholder="请输入原密码"
              v-model="ruleFormAdd.oldPassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属公司" prop="newPassword">
            <el-select
              class="common-screen-input_330"
              v-model="ruleFormAdd.newPassword"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属部门" prop="verifyPassword">
            <el-select
              class="common-screen-input_330"
              v-model="ruleFormAdd.newPassword"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属小组" prop="verifyPassword">
            <el-select
              class="common-screen-input_330"
              v-model="ruleFormAdd.newPassword"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属平台" prop="verifyPassword">
            <el-select
              class="common-screen-input_330"
              v-model="ruleFormAdd.newPassword"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属类目" prop="verifyPassword">
            <el-select
              class="common-screen-input_330"
              v-model="ruleFormAdd.newPassword"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属客户" prop="verifyPassword">
            <el-select
              class="common-screen-input_330"
              v-model="ruleFormAdd.newPassword"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公海关系" prop="verifyPassword">
            <el-select
              class="common-screen-input_330"
              v-model="ruleFormAdd.newPassword"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属标签" prop="oldPassword">
            <el-input
              class="common-screen-input_330"
              placeholder="请输入原密码"
              v-model="ruleFormAdd.oldPassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="oldPassword">
            <el-input
              class="common-screen-input_330"
              placeholder="请输入原密码"
              v-model="ruleFormAdd.oldPassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="oldPassword">
            <el-input
              class="common-screen-input_330"
              placeholder="请输入原密码"
              v-model="ruleFormAdd.oldPassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="商务负责" prop="oldPassword">
            <el-input
              class="common-screen-input_330"
              placeholder="请输入原密码"
              v-model="ruleFormAdd.oldPassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务时间" prop="oldPassword">
            <el-date-picker
              v-model="ruleFormAdd.Day"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="预警时间" prop="oldPassword">
            <el-radio-group v-model="ruleFormAdd.radio">
              <el-radio-button label="1">提前3天</el-radio-button>
              <el-radio-button label="2">提前7天</el-radio-button>
              <el-radio-button label="3">提前15天</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="当日发送时间" prop="oldPassword">
            <el-time-picker
              v-model="ruleFormAdd.time"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="任意时间点"
            >
            </el-time-picker>
          </el-form-item>
        </el-form>
        <div style="text-align: center">
          <el-button class="common-screen-btn" type="primary" @click="handleClose()"
            >确 定</el-button
          >
          <el-button class="common-screen-btn" @click="handleClose()">取 消</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  props: ["drawer", "tableRowId"],
  data() {
    return {
      ruleFormAdd: {
        // 新增店铺数据
        oldPassword: "",
        newPassword: "",
        verifyPassword: "",
        lable: [],
        date: "",
        Day: "",
        radio: "1",
        time: "",
      }, // form提交数据
      rulesAdd: {
        // 校验规则
        oldPassword: [{ required: true, message: "请输入原密码", trigger: "change" }],
        newPassword: [{ required: true, message: "请输入新密码", trigger: "change" }],
        verifyPassword: [{ required: true, message: "请确认新密码", trigger: "change" }],
      },
      roleSelect: "",
      options: [
        // 筛选下拉
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
    };
  },
  methods: {
    handleClose(done) {
      this.$emit("handleClose", done);
    },
  },
};
</script>
<style lang="less" scoped>
.cusListAdd-drawer {
  .upload-icon-input {
    width: 88px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .drawer-container {
    padding: 0 24px;
    text-align: left;
  }
  .upload-icon-container {
    display: inline-block;
    position: relative;
    width: 88px;
    height: 40px;
    .common-screen-btn {
      margin: 0;
    }
  }
  //   /deep/ .el-drawer__open .el-drawer.rtl {
  //     width: 38% !important;
  //   }
}
</style>
